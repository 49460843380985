import Email from "../models/email";
import axios from "axios";

const sendMail = async (email: Email) : Promise<any> => {
  return await axios.post('https://api.perygrene.co.uk/contact',{
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  body: JSON.stringify({
    name: email.yourName,
    email: email.yourEmail,
    subject: email.subject,
    message: email.message,
    toAddress: "contact@perygrene.vn"
  })
  })
  .then(res => {
  // console.log(res.data.statusCode)
  return res.data.statusCode;
  })
  .catch(err => {
  // console.log(err)
  return err
  })
}

export default sendMail;