import consulting from "../../assets/img/presentation.png";
import heroImg from "../../assets/img/alaska.png";

export default function Consulting(props: any){
  return (
    <>
      <br />
      <br />
      <div className="container-fluid bg-primary feature pt-5" style={{backgroundImage: `url(${heroImg})`}}>
        <div className="container pt-5">
          <div className="row g-5" style={{ backgroundColor: 'rgba(0,0,0, 0.6)'}}>
            <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow" data-wow-delay="0.3s">
              <h2 className="mb-4 text-light">Since 2021 Perygrene Ltd has consulted for clients across a variety of projects. Examples include:</h2>
              <div className="d-flex align-items-center text-light mb-3">
                <div className="btn-sm bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>M&A: Offshore and onshore oil and gas, internationally and in Vietnam regarding sales and acquisition, 
                notably USA (non-operated, development) and Vietnam (operated and non-operated, both development and producing)</span>
              </div>
              <div className="d-flex align-items-center text-light mb-3">
                <div className="btn-sm bg-white text-primary rounded-circle me-3">
                  <i className="fa fa-check"></i>
                </div>
                <span>Project Development: Offshore wind power for Vietnam (originated, operated) and Taiwan (originated, non-operated)</span>
              </div>
              <div className="d-flex align-items-center text-light mb-3">
                <div className="btn-sm bg-white text-primary rounded-circle me-3">
                    <i className="fa fa-check"></i>
                </div>
                <span>Project Management: company-wide stage gate process drafting and adoption for offshore wind project management.</span>
              </div>
              <div className="d-flex align-items-center text-light mb-3">
                <div className="btn-sm bg-white text-primary rounded-circle me-3">
                    <i className="fa fa-check"></i>
                </div>
                <span>Vietnam value-chain analyses: offshore wind and gas to power</span>
              </div>
              <div className="d-flex align-items-center text-light mb-4">
                <div className="btn-sm bg-white text-primary rounded-circle me-3">
                    <i className="fa fa-check"></i>
                </div>
                <span>EV infrastructure: city transitions, reflections on international experience and petroleum's transition to electrons.</span>
              </div>
              <div className="d-flex align-items-center text-white mb-4">
                <div className="btn-sm bg-white text-primary rounded-circle me-3">
                    <i className="fa fa-check"></i>
                </div>
                <span>Founding member and coordination of BritCham Vietnam's Energy Working Group (EWG)</span>
              </div>
            </div>
            <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">

                <img className="img-fluid align-items-center" alt="about" src={consulting} style={{"width": "500px", "height": "400px"}}/>

            </div>
          </div>

      </div>
    </div>
    </>
  )
}