import Member from "../models/member";
import Members from "./Member";
import team from "../data/team";
import useWindowSize from "../hook/useWindowSize";
import MemberMobile from "./MemberMobile";
import { Carousel } from "react-bootstrap";
import { useState } from "react";

export default function Team(){
  const size = useWindowSize();
  const isMobile = size.width < 700;
  isMobile ? console.log("Mobile") : console.log("Desktop");
  const [paused, setPaused] = useState(false);

  const handlePause = () => setPaused(true);
  const handlePlay = () => setPaused(false);

  return (
    <div className="container-fluid bg-light py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">

          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Team</div>
            <h1 className="mb-4">Our experience spans the international oil, gas to power and offshore wind sectors,
            with a strong focus on Vietnam.</h1>
            <p className="mb-4">
              We have been working for 30 years across Vietnam's gas industry and market developments; including the first gas to power value chain project and
              other major gas (and oil) resource exploitation projects. We have some focus on gas sales negotiations and delivering exploration/appraisal/development
              programmes. Latterly the preparations for import of LNG, the strong emergence of renewables, the dawn of an era of offshore wind and full cycle to the UK's thermonuclear power sector.
            </p>
          </div>
          {isMobile ? (
            <Carousel interval={1600} controls={true} indicators={true} pause={paused ? 'hover' : false}>
              {team.map((member: Member, index: number) => {
                return (
                  <Carousel.Item key={index} onMouseEnter={handlePause} onMouseLeave={handlePlay}>
                    <div onClick={handlePause}>
                      <MemberMobile {...member} />
                    </div>
                  </Carousel.Item>
                  )})}
            </Carousel>
          ) : (
            <div className="col-lg-7">
              <div className="row g-4">
                {team.map((member: Member)=> {
                  return (
                    <Members {...member} />
                  )
                })
                }
              </div>
            </div>
          )}

        </div>
      </div>
    </div>

  )
}