import About from "../components/About";

export default function AboutRoute(){
  return (
    <>
    <About 
      paragraph2="We are a consultancy with primary strength and expertise in pursuing and managing energy related assets for/with investors."
      paragraph1="Perygrene Ltd supports clients' ambitions in Vietnam's growing and demanding energy sector and
              regionally. Alongside technical and operational experience, Perygrene Ltd's team has extensive
              general and asset management, including, tax and finance experience."
      paragraph3="We advise and introduce investors to renewable solutions, including power generation and energy storage, including solar, wind, hydrogen, liquid air and other technologies in Vietnam"
      paragraph4="We have expertise in the offshore wind, LNG and gas to power sector, carbon capture and storage, as well as the offshore oil and gas sectors."
    />
    </>
  )
}