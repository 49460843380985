import Member from "../models/member";
import { Modal, Button, Carousel } from "react-bootstrap";
import { useState } from "react";
import ImageCarousel from "./ImageCarousel";

export default function Members(props: Member){
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const popover = (member: Member): JSX.Element => (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{member.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong className="fs-7">{member.description}</strong>
        <Carousel>
          <ImageCarousel links={member.links}/>
        </Carousel>
      </Modal.Body>
    </>
  );
  return (
    <>
    <div className="col-md-6">
      <div className="row g-4">
        <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
          <div className="team-item bg-white text-center rounded p-4 pt-0">
            <img className="img-fluid rounded-circle p-4" src={props.imageURL} alt=""/>
            <h5 className="mb-0">{props.name}</h5>
            <small>{props.title}</small>
            <br />
            <br />
            <Button type="button" className="btn btn-outline-primary rounded-pill text-white px-4" onClick={handleShow}>
                Read More
              </Button>
            <div className="d-flex justify-content-center mt-3">
              { props.name === "Dr. Hugh Sykes" ?
              <a className="btn btn-square btn-primary m-1" rel="noreferrer" target={"_blank"} href={props.linkedIn}><i className="fab fa-linkedin-in"></i></a>
              : null
              }
              </div>
          </div>
        </div>
      </div>
    </div>
    <Modal show={show} onHide={handleClose}>
      {popover(props)}
      <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}