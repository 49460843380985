import Member from "../models/member";
import hugh from "../assets/img/hughsykes.jpg";
import nga from "../assets/img/nga.jpg";
import quang from "../assets/img/quang.jpg";
import hoan from "../assets/img/hoan.jpg";

const team: Member[] = [
  {
    name: "Dr. Hugh Sykes",
    title: "Managing Director & Owner",
    description: "Hugh started work in the UK power sector and has been focused on Vietnam since 1995. An experienced director and general manager, Hugh's focus has been in offshore gas and LNG projects to pipelines and power, as well as offshore oil and gas exploration. Hugh's Perygrene work includes projects for offshore wind, carbon capture and storage (CCS) and energy storage in addition to upstream consulting. His career spans international roles and projects with BP, GEC Turbines, Mubadala Petroleum, Rolls Royce and Salamander Energy at locations in the UK, UAE, Vietnam, Indonesia and Australia. A Vietnam resident, Hugh enjoys a broad network of contacts across the energy sector.",
    imageURL: hugh,
    numberOfLinks: 4,
    links: ["https://britchamvn.com/", "https://www.spe.org/en/", "https://www.imeche.org/", "https://www.aien.org/"],
    linkedIn: "https://vn.linkedin.com/in/hugh-sykes-20804810",
  },
  {
    name: "Ms. Nguyen Thi Thu Nga",
    title: "Director & Legal Representative",
    description: "Nguyễn Thị Thu Nga has fifteen (15) years experience in the Vietnam petroleum sector with international energy companies; Pearl Energy, Mubadala Petroleum and SK. Nga is a law graduate, registered with the Vietnam Bar Federation under the Bar Association of Ho Chi Minh City. She is experienced across supply chain, joint ventures and offshore licencing and operations. Nga’s upstream work includes sales and purchase agreements, joint venture agreements, petroleum licences, supply chain contracts, and product purchase and sales agreements. She has worked extensively with senior executives both in foreign investors and Government entities. Also she has held responsibility for in country compliance and ethics and has a detailed knowledge of Vietnamese business formalities and approvals.",
    imageURL: nga,
    numberOfLinks: 1,
    links: ["https://aien.org/"],
    linkedIn: "",
  },
  {
    name: "Mr. Phan Quang",
    title: "MITTACO M.D. Tax & Finance",
    description: "Quang has deep, practical understanding of various industries including energy, petroleum, mining, retail, education, medicare, hospitality,automobiles, FMCGs and animal feeds. He had also worked for Big Four firms in Vietnam (EY, Coopers & Lybrand) focusing on taxation. Quang graduated from Ho Chi Minh City University of Economics, specialized in Finance and holds a postgraduate degree on Management from Central Queensland University, Australia. Mr Quang is Managing Director of MITACCO, a boutique consulting firm.",
    imageURL: quang,
    numberOfLinks: 1,
    links: ["https://britchamvn.com/"],
    linkedIn: "",
  },
  {
    name: "Mr. Tran Quang Hoang",
    title: "Technical Director",
    description: "Mr Hoan has over 30 years experience as a geoscientist, recently leaving Mubadala Petroleum as exploration manager in Vietnam. Hoan has worked for BP, Unocal (Chevron) and Petrovietnam with assignments including Malaysia. Hoan holds a Masters degree from Brunei. His expertise spans exploration work from frontier to infrastructure-led programmes as well as reserves estimation and reporting.",
    imageURL: hoan,
    numberOfLinks: 0,
    links: [],
    linkedIn: "",
    }
  ];

export default team;