import sendMail from "../hook/sendmail";
import { useState } from "react";

export default function ContactRoute(){
  const [yourName, setYourName] = useState("");
  const [yourEmail, setYourEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertHidden, setAlertHidden] = useState("true");

  // Implement alert-dismissing button
  const handleAlert = async (e: any) => {
    e.preventDefault();
    const alert = document.getElementsByClassName("alert")[0];
    setAlertHidden("true");
    alert.setAttribute("hidden", alertHidden);
  }

  // Implement form submission api call to Amazon SES
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const statusCode = await sendMail({
      yourName: yourName,
      yourEmail: yourEmail,
      subject: subject,
      message: message
    })

    console.log(statusCode)
    setAlertHidden("false")

    if (statusCode === 200){
      // set succesful alert
      setAlertType("success");
      setAlertMessage("Your message has been sent successfully");
      
    } else {
      // set danger alert
      setAlertType("danger");
      setAlertMessage("Your message has not been sent. Please try again later");
    }

    setMessage("");
    setSubject("");
    setYourEmail("");
    setYourName("");
  }

  const validateAlert = (type: string, alertHidden: string) => {
    let hidden = false;
    if (alertHidden === "true") {
      hidden = true;
    } else {
      hidden = false;
    }
    if (type === "success"){
      return (
        <div className="col-12">
          <div className="alert alert-success alert-dismissible" role="alert">
            <div>{alertMessage}</div>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlert}></button>
          </div>
        </div>
      )
    } else if (type === "danger"){
      return (
        <div className="col-12">
          <div className="alert alert-danger alert-dismissible" role="alert">
            <div>{alertMessage}</div>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlert}></button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="col-12">
          <div className="alert alert-danger alert-dismissible" hidden={hidden} role="alert">
            <div>{alertMessage}</div>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlert}></button>
          </div>
        </div>
      )
    }
  }

  return (
    <>
    <div className="container-fluid py-5">
        <div className="container py-5">
            <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{"maxWidth": "500px"}}>
                <h1 className="mb-4">If You Have Any Query, Please Contact Us</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <p className="text-center mb-4"><a href="mailto:contact@perygrene.vn">contact@perygrene.vn</a></p>
                <div className="wow fadeIn" data-wow-delay="0.3s">
                  <form action="POST" onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="name" placeholder="Your Name" value={yourName} onChange={(e) => setYourName(e.target.value)}/>
                          <label htmlFor="name">Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input type="email" className="form-control" id="email" placeholder="Your Email" value={yourEmail} onChange={(e) => setYourEmail(e.target.value)}/>
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="subject" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)}/>
                          <label htmlFor="subject">Subject</label>
                        </div>
                      </div>
                        <div className="col-12">
                            <div className="form-floating">
                                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{"height": "150px"}} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                <label htmlFor="message">Message</label>
                            </div>
                        </div>
                        <div className="col-12">
                          <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                        </div>
                        {validateAlert(alertType, alertHidden)}
                      </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
    </div>
    </>
  )
}