import aboutImg from "../assets/img/dsc_2634.jpg";

export default function About(props: any){
  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="about-img">
              <img className="img-fluid" alt="about" src={aboutImg}/>
            </div>
        </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div> */}
            <p className="mb-4">{ props.paragraph1 }</p>
            <p className="mb-4">
              { props.paragraph2 }
            </p>
            <p className="mb-4">
              { props.paragraph3 }
            </p>
            <p className="mb-4">
              { props.paragraph4 }
            </p>
          </div>
      </div>
    </div>
  </div>
  )
}