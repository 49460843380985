import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './routes/Main';
import Error from './routes/Error';
import ServiceRoute from './routes/Service';
import ContactRoute from './routes/Contact';
import AboutRoute from './routes/About';
import AssetManagement from './routes/services/AssetManagement';
import Consulting from './routes/services/Consulting';
import Team from './components/Team';
import Introduction from './routes/services/Introductions';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Main />
      },
      {
        path: "/about",
        element: <AboutRoute />
      },
      {
        path: "/contact",
        element: <ContactRoute />
      },
      {
        path: "/team",
        element: <Team />
      },
      {
        path: "/services",
        element: <ServiceRoute />,
      },
      {
        path: "/services/assetManagement",
        element: <AssetManagement
          header1="Asset & Project Management"
          paragraph1="
          The Perygrene Ltd team has experience successfully developing fit for purpose operating and
          project resources for large and small, offshore and onshore operations in the Vietnam energy sector.

          We aim to deliver a right-sized, tailored, multi-disciplined and flexible resource for short 
          or long term operations or projects in Vietnam. Most consulting projects involve between two (2) and 20 contributors."
          paragraph2="Whether reporting direct to a CEO/COO, or reporting into an existing, functional organization, 
          Perygrene Ltd aims to apply Vietnam-based professional experience seamlessly into your operated asset management.

          We also have experience in managing the more difficult task off successfully closing and transitioning teams.
          Compliance and ethics are at the core of our experience and expertise."
          
          header2="Non-operated Asset Management"
          paragraph3="The Perygrene Ltd team has extensive experience in managing investor interests in non-operated assets in the energy sector."
          paragraph4="Non-operated asset management mirrors operated asset management and benefits greatly from having resources and experience in both."
        />
      },
      {
        path: "/services/consulting",
        element: <Consulting
          header1="Consulting"
          paragraph1="Since its conception Perygrene Ltd has provided a wide variety of ad hoc consulting services from:"
          paragraph2="Asset assessments, both international and Vietnam located operating assets"
          paragraph3="Short term or longer term, part or full time"
        />
      },
      {
        path: "/services/i&dIntroductions",
        element: <Introduction
          header1="Investor/Divestor Introductions"
          paragraph1="Perygrene Ltd makes traditional investor/divestor introductions accross oil, gas/LNG and renewables assets in the Vietnam market and abroad."
          paragraph2="Also, Perygrene Ltd makes business introductions and explores new networks for initiatives towards zero carbon initiatives such as:"
        />
      },
    ],
    errorElement: <Error />
  },

]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
