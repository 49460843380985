import Service from "../components/Service";
import Team from "../components/Team";
import heroImg from "../assets/img/alaska.png";

export default function Main(){
  return (
    <>
      {/* <About 
        paragraph1="Sustainability is at the forefront of Perygrene Ltd's work with clients."
        paragraph2="We have expertise in the offshore wind, LNG and gas to power sector, carbon capture and storage, as well as the offshore oil and gas sectors."
        paragraph3="We advise and introduce investors to renewable solutions, including power generation and energy storage, including solar, wind, hydrogen, liquid air and other technologies in Vietnam"
      /> */}
      <Service />
      <div className="container-fluid bg-primary feature pt-5" style={{backgroundImage: `url(${heroImg})`}}>
        <div className="container pt-5">
          <div className="row g-5">
            <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
              <div className="mb-4 text-light"></div>
            </div>
          </div>
        </div>
      </div>
      <Team />
    </>
  )
}