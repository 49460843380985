import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import hero from "../assets/img/DSC_2646.jpg";

export default function Hero(props: any){

  const currentLocation = useLocation();
  let isHome = false
  if (currentLocation.pathname === "/"){
    isHome = true
  }
  return (
    <>
    {isHome ?
    <div className="container-fluid pt-5 hero-header mb-5" style={{ backgroundColor: 'rgba(0,0,0, 0.4)', backgroundImage: `url(${hero})`, backgroundBlendMode: "overlay"}}>
      <div className="container pt-5">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5 text-white" >
            <p className="mb-4 animated slideInRight">
              Perygrene Ltd supports clients' ambitions in Vietnam's growing and demanding energy sector and
              regionally. Alongside technical and operational experience, Perygrene Ltd's team has extensive
              general and asset management, including, tax and finance experience.
            </p>
            <NavLink to={"/contact"} className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</NavLink>
          </div>
        </div>
      </div>
      <br/>
    </div>
  :
  <div className="container-fluid pt-5 hero-header"style={{backgroundImage: `url(${hero})`}}>
    <div className="container pt-5">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                <li className="breadcrumb-item"><NavLink className="text-white" to={"/"}>Home</NavLink></li>
                <li className="breadcrumb-item"><NavLink className="text-white" to={`${props.route1}`}>{props.path1}</NavLink></li>
                {props.hasChildren ? <li className="breadcrumb-item"><NavLink className="text-white" to={`${props.route2}`}>{props.path2}</NavLink></li>:  null }
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 align-self-end text-center text-lg-end"></div>
        </div>
    </div>
  </div>
  }
  </>
  )
}