import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './utils/scrollToTop';
import Hero from './components/Hero';
import { useLocation } from 'react-router-dom';


function App() {
  const currentLocation = useLocation();
  let header, path1, path2, route1, route2 = ""
  let hasChildren = false
  switch (currentLocation.pathname){
    case "/contact":
      header = "Contact Us"
      path1 = "Contact"
      route1 = "/contact"
      hasChildren = false
      break;
    case "/about":
      header = "About Us"
      path1 = "About"
      route1 = "/about"
      hasChildren = false
      break;
    case "/services":
      header = "Our Services"
      path1 = "Services"
      route1 = "/services"
      hasChildren = false
      break;
    case "/team":
      header = "Our Team"
      path1 = "Team"
      route1 = "/team"
      hasChildren = false
      break;
    case "/services/assetManagement":
      header = "Asset Management"
      path1 = "Services"
      path2 = "Asset Management"
      route1 = "/services"
      route2 = "/services/assetManagement"
      hasChildren = true
      break;
    case "/services/consulting":
      header = "Consulting"
      path1 = "Services"
      path2 = "Consulting"
      route1 = "/services"
      route2 = "/services/consulting"
      hasChildren = true
      break;
    case "/services/i&dIntroductions":
      header = "Investor/Divestor Introductions"
      path1 = "Services"
      path2 = "IDIntroductions"
      route1 = "/services"
      route2 = "/services/i&dIntroductions"
      hasChildren = true
      break;
  }

  return (
    <>
    <ScrollToTop />
    <Header
      companyName="Perygrene Ltd"
    />
    <Hero
      title={header}
      path1={path1}
      path2={path2}
      route1={route1}
      route2={route2}
      hasChildren={hasChildren}
    />
    <Outlet />
    
    <Footer 
      address="1418A 14, Thao Dien Street, Thao Dien Ward, District 2, Ho Chi Minh City, Vietnam"
      mobile="+84 903839983"
      email="contact@perygrene.vn"
    />
    </>
  );
}

export default App;
