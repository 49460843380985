import { NavLink } from "react-router-dom";
import aboutImg from "../../assets/img/taiwan.jpg";

export default function Introduction(props: any){
  return (
    <>
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="about-img">
              <img className="img-fluid" alt="assets" src={aboutImg}/>
            </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <h1 className="mb-4">{ props.header1 }</h1>
            <p className="mb-4">
              { props.paragraph1 }
            </p>
            <p className="mb-4">
              { props.paragraph2 }
            </p>
            <div className="row g-3">
              <div className="col-sm-6">
                <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Carbon Capture & Storage</h6>
                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Methane Detection</h6>
              </div>
              <div className="col-sm-6">
                <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Green Hydrogen</h6>
                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Energy Storage</h6>
              </div>
              <div className="col-sm-6">
                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Electric vehicles</h6>
              </div>
            </div>
            <br />
            <NavLink to={"/services"} className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Back to Our Services</NavLink>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}