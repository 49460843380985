import Carousel from 'react-bootstrap/Carousel';
import britCharm from '../assets/img/britcharm.jpeg';
import spe from '../assets/img/spe.jpeg';
import iome from '../assets/img/iome.png';
import aien from '../assets/img/aien.jpeg';

const checkImage = (link: string) => {
    if (link === "https://britchamvn.com/") {
      return britCharm;
    } else if (link === "https://www.spe.org/en/") {
      return spe;
    } else if (link === "https://www.imeche.org/") {
      return iome;
    } else {
      return aien;
    }
  }

export default function ImageCarousel(props: any) {
  return (
    <Carousel interval={1600} controls={true} indicators={true} style={{height: "270px"}}>
      {props.links.map((link: string)=> {
        return (
          <Carousel.Item>
          <a href={link} rel="noreferrer" target='_blank'>
              <img className="d-block w-100 mb-4" src={checkImage(link)} alt="members" />
          </a>
        </Carousel.Item>
        )
      })}

    </Carousel>
  );
}